import { WppButton, WppIconWarning, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useDeleteIndustryApi } from 'api/masterData/mutations/useDeleteIndustryApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { handleReloadIndustriesListTable } from 'pages/industries/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Industry } from 'types/masterData/industries'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  industry: Industry
}

const DeleteIndustryModal = ({ id, industry, isOpen, onClose, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleDeleteItem, isLoading } = useDeleteIndustryApi()

  const handleDelete = async () => {
    try {
      await handleDeleteItem({
        id: industry.id,
      })

      await handleReloadIndustriesListTable()

      enqueueToast({
        message: t('common.toasts.delete', { entity: t('common.master_data.entities.industry') }),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('common.errors.general'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }

  return (
    <Modal
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isLoading}
      data-testid={id}
    >
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center">
          <WppIconWarning />
          {t('master_data.delete_item.header', {
            entity: t('common.master_data.entities.industry'),
          })}
        </Flex>
      </WppTypography>

      <WppTypography slot="body" type="s-body">
        {t('master_data.delete_item.description', {
          entity: t('common.master_data.entities.industry'),
        })}
      </WppTypography>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="secondary" size="s" data-testid="cancel">
          {t('common.cancel')}
        </WppButton>
        <WppButton onClick={handleDelete} size="s" variant="destructive" loading={isLoading} data-testid="apply">
          {t('common.delete')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteIndustryModal } = createNiceModal(DeleteIndustryModal, 'delete-industry-modal')

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

export const nameFieldCharacterMaxCount = 256
export const nameFieldCharacterMinCount = 2

export const useIndustryValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        name: zod
          .string()
          .trim()
          .min(
            nameFieldCharacterMinCount,
            t('master_data.validation.name.min', {
              entity: t('common.master_data.entities.industry'),
              characterCount: nameFieldCharacterMinCount,
            }),
          )
          .max(
            nameFieldCharacterMaxCount,
            t('master_data.validation.name.max', {
              entity: t('common.master_data.entities.industry'),
              characterCount: nameFieldCharacterMaxCount,
            }),
          ),
      }),
    [t],
  )
}

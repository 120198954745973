import { MayBeNull } from '@wpp-open/core'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { AvatarCropEdition } from 'components/common/avatar/avatarCropEdition/AvatarCropEdition'
import { Flex } from 'components/common/flex/Flex'
import { useUploadMasterDataFilesApi } from 'hooks/attachments/useUploadMasterDataFilesApi'
import { excludeFalsy } from 'utils/common'
import { useInMemoryFileUrl } from 'utils/files'

interface Props {
  name?: string
}

interface State {
  logoThumbnailTempFile: MayBeNull<File>
  logoOriginalTempFile: MayBeNull<File>
}

export const ManageMarketLogoUpload = ({ name }: Props) => {
  const { t } = useTranslation()

  const handleUploadMasterDataFiles = useUploadMasterDataFilesApi()

  const [{ logoThumbnailTempFile, logoOriginalTempFile }, setState] = useSetState<State>({
    logoThumbnailTempFile: null,
    logoOriginalTempFile: null,
  })

  const logoThumbnailTempUrl = useInMemoryFileUrl(logoThumbnailTempFile)
  const logoOriginalTempUrl = useInMemoryFileUrl(logoOriginalTempFile)

  const { watch, setValue } = useFormContext()

  const [logoOriginal, logoThumbnail] = watch(['logoOriginal', 'logoThumbnail'])

  const handleUploadFiles = async ([logoThumbnail, logoOriginal]: File[]) => {
    setState({ logoThumbnailTempFile: logoThumbnail, ...(logoOriginal && { logoOriginalTempFile: logoOriginal }) })
    const [logoThumbnailMetadata, logoOriginalMetadata] = await handleUploadMasterDataFiles(
      [logoThumbnail, logoOriginal].filter(excludeFalsy),
    )
    setValue('logoThumbnail', logoThumbnailMetadata)
    logoOriginalMetadata && setValue('logoOriginal', logoOriginalMetadata)
  }

  const handleDelete = () => {
    setState({ logoThumbnailTempFile: null, logoOriginalTempFile: null })
    setValue('logoThumbnail', null)
    setValue('logoOriginal', null)
  }

  return (
    <Flex gap={10} align="center">
      <AvatarCropEdition
        title={t('master_data.fields.logo.edit_logo')}
        avatarName={name}
        avatarSize="l"
        avatarEditorSize={{ width: 504, height: 504 }}
        avatarOriginalUrl={logoOriginalTempUrl || logoOriginal?.url}
        avatarThumbnailUrl={logoThumbnailTempUrl || logoThumbnail?.url}
        handleUploadFiles={handleUploadFiles}
        handleDeleteFiles={handleDelete}
      />
    </Flex>
  )
}

import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { Industry } from 'types/masterData/industries'

type Params = PaginationParams<{
  ids?: string[]
  search?: string
}>

export type IndustriesListResponse = PaginatedResponse<Industry>

export const fetchIndustriesList = ({ ids, search, page, itemsPerPage }: Params) =>
  facadeApi.get<IndustriesListResponse>('/industries', {
    params: {
      page,
      itemsPerPage,
      filter: {
        ...(!!ids && { ids }),
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })

import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateClientApi } from 'api/masterData/mutations/useCreateClientApi'
import { useEditClientApi } from 'api/masterData/mutations/useEditClientApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import styles from 'pages/clients/manageClientModal/ManageClientModal.module.scss'
import { useClientValidationScheme } from 'pages/clients/manageClientModal/utils'
import { handleReloadClientsListTable } from 'pages/clients/utils'
import { hideViewClientModal } from 'pages/clients/viewClientModal/ViewClientModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { ClientForm, Client, ClientFormDTO } from 'types/masterData/clients'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

const defaultValues: ClientForm = {
  name: '',
  logoThumbnail: null,
  logoOriginal: null,
}

interface Props extends NiceModalWrappedProps {
  client?: MayBeNull<Client>
}

const ManageClientModal = ({ isOpen, client, onCloseComplete, onClose, id }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const { mutateAsync: handleCreate } = useCreateClientApi()
  const { mutateAsync: handleEdit } = useEditClientApi()

  const form = useForm({
    defaultValues: client || defaultValues,
    validationSchema: useClientValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: ClientFormDTO) => {
    try {
      await handleCreate({
        client: values,
      })

      await handleReloadClientsListTable()

      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.client') }),
        type: 'success',
      })

      onClose()
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: ClientForm) => {
    try {
      await handleEdit({
        id: client!.id,
        client: values,
      })

      await handleReloadClientsListTable()

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_CLIENTS] })

      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.client') }),
        type: 'success',
      })

      onClose()
      hideViewClientModal()
    } catch {
      onError()
    }
  }

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{
          onSubmit: handleSubmit(client ? onEditSubmit : onCreateSubmit),
        }}
        data-testid={id}
        open={isOpen}
        size="m"
        disableOutsideClick
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t(client ? 'master_data.manage_modal.header.edit' : 'master_data.manage_modal.header.add', {
            entity: t('common.master_data.entities.client'),
          })}
        </WppTypography>

        <Flex className={styles.modal} slot="body" direction="column" gap={28}>
          <Flex direction="column" gap={24} slot="body">
            <Flex align="center">
              <ManageMasterDataLogoUpload name={client?.name || ''} />
            </Flex>
          </Flex>
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
            }}
            required
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" loading={isSubmitting} data-testid="apply">
            {t('common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showManageClientModal } = createNiceModal(ManageClientModal, 'edit-client-modal')

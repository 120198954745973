import {
  WppTypography,
  WppIconTableSort,
  WppIconTableSortAsc,
  WppIconTableSortDesc,
} from '@platform-ui-kit/components-library-react'
import { IHeaderParams } from 'ag-grid-community'

import { Flex } from 'components/common/flex/Flex'

export const DefaultColumnHeader = ({ enableSorting, column, setSort, displayName }: IHeaderParams) => {
  const handleSortChange = () => {
    const sort = column.getSort() ?? null
    const sortingOrder = column.getColDef().sortingOrder!
    const sortIndex = sortingOrder.indexOf(sort)
    const nextSortIndex = sortIndex === sortingOrder.length - 1 ? 0 : sortIndex + 1

    setSort(sortingOrder[nextSortIndex])
  }

  const SortIcon = column.isSortAscending()
    ? WppIconTableSortAsc
    : column.isSortDescending()
    ? WppIconTableSortDesc
    : WppIconTableSort

  return (
    <Flex
      className="ag-header-cell-comp"
      justify="between"
      {...(enableSorting && {
        onClick: handleSortChange,
      })}
    >
      <WppTypography type="s-strong">{displayName}</WppTypography>
      {enableSorting && <SortIcon className="ag-sorting" />}
    </Flex>
  )
}

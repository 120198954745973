import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { showDeleteMarketModal } from 'pages/markets/deleteMarketModal/DeleteMarketModal'
import { Market } from 'types/masterData/markets'
import { RegionsById } from 'types/masterData/regions'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onEdit: () => void
  market: Market
  regionsById: RegionsById
}

const ViewMarketModal = ({ isOpen, market, regionsById, onCloseComplete, onClose, onEdit, id }: Props) => {
  const { t } = useTranslation()

  const { name, isoAlpha2, isoAlpha3, regionId, logoThumbnail } = market

  const region = regionId && regionsById[regionId]

  return (
    <SideModal
      open={isOpen}
      onWppSideModalCloseComplete={onCloseComplete}
      onWppSideModalClose={onClose}
      size="m"
      data-testid={id}
    >
      <WppTypography slot="header" type="2xl-heading">
        {name}
      </WppTypography>
      <Flex direction="column" gap={24} slot="body">
        <Flex align="center">
          <Avatar slot="left" size="l" name={name || ''} src={logoThumbnail?.url || ''} />
        </Flex>

        <Flex direction="column">
          <WppTypography type="m-strong">{t('master_data.fields.iso_alpha_2.label')}</WppTypography>
          <WppTypography type="s-body">{isoAlpha2}</WppTypography>
        </Flex>

        <Flex direction="column">
          <WppTypography type="m-strong">{t('master_data.fields.iso_alpha_3.label')}</WppTypography>
          <WppTypography type="s-body">{isoAlpha3}</WppTypography>
        </Flex>

        {region && (
          <Flex direction="column">
            <WppTypography type="m-strong">{t('master_data.fields.region.label')}</WppTypography>
            <WppTypography type="s-body">{region.name}</WppTypography>
          </Flex>
        )}
      </Flex>

      <Flex slot="actions" gap={12} justify="between">
        <WppButton variant="destructive" onClick={() => showDeleteMarketModal({ market, onClose })}>
          {t('common.delete')}
        </WppButton>
        <Flex gap={12}>
          <WppButton variant="secondary" onClick={onEdit}>
            {t('common.edit')}
          </WppButton>
          <WppButton variant="primary" size="m" onClick={onClose}>
            {t('common.done')}
          </WppButton>
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showViewMarketModal, hideModal: hideViewMarketModal } = createNiceModal(
  ViewMarketModal,
  'view-market-modal',
)

import { WppButton, WppIconWarning, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useDeleteAgencyApi } from 'api/masterData/mutations/useDeleteAgencyApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { handleReloadAgenciesListTable } from 'pages/agencies/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Agency } from 'types/masterData/agencies'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  agency: Agency
}

const DeleteAgencyModal = ({ id, agency, isOpen, onClose, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleDeleteItem, isLoading } = useDeleteAgencyApi()

  const handleDelete = async () => {
    try {
      await handleDeleteItem({
        id: agency.id,
      })

      await handleReloadAgenciesListTable()

      enqueueToast({
        message: t('common.toasts.delete', { entity: t('common.master_data.entities.agency') }),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('common.errors.general'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }

  return (
    <Modal
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isLoading}
      data-testid={id}
    >
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center">
          <WppIconWarning />
          {t('master_data.delete_item.header', {
            entity: t('common.master_data.entities.agency'),
          })}
        </Flex>
      </WppTypography>

      <WppTypography slot="body" type="s-body">
        {t('master_data.delete_item.description', {
          entity: t('common.master_data.entities.agency'),
        })}
      </WppTypography>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="secondary" size="s" data-testid="cancel">
          {t('common.cancel')}
        </WppButton>
        <WppButton onClick={handleDelete} size="s" variant="destructive" loading={isLoading} data-testid="apply">
          {t('common.delete')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteAgencyModal } = createNiceModal(DeleteAgencyModal, 'delete-agency-modal')

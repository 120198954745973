import { useCallback } from 'react'

import { useFetchBrandsApi } from 'api/masterData/queries/useFetchBrandsApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableDefaults, TableKey } from 'constants/table'
import { useMasterDataAttachments } from 'hooks/attachments/useMasterDataAttachments'
import { queryClient } from 'providers/osQueryClient/utils'
import { Brand } from 'types/masterData/brands'
import { excludeFalsy } from 'utils/common'

interface Params {
  search?: string
}

export const handleReloadBrandsListTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_BRANDS] })
  tableActions.reload(TableKey.MASTER_DATA_BRANDS)
}

export const useBrandsLoader = ({ search }: Params) => {
  const handleFetchBrandsList = useFetchBrandsApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const { handleLoadAttachments, getAttachmentUrlByKey } = useMasterDataAttachments({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<Brand> = useCallback(
    async ({ startRow, endRow }) => {
      const size = endRow - startRow

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchBrandsList({
        itemsPerPage: size,
        search,
        page: endRow / size,
      })

      const thumbnailKeys = data.map(({ logoThumbnail }) => logoThumbnail?.key).filter(excludeFalsy)
      await handleLoadAttachments(thumbnailKeys)

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [search, handleFetchBrandsList, handleLoadAttachments],
  )

  return { getAttachmentUrlByKey, loader }
}

import { ComponentProps, PropsWithChildren } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { NavigationTabs } from 'components/navigationTabs/NavigationTabs'
import styles from 'pages/components/tableNavWrapper/TableNavWrapper.module.scss'

interface Props extends ComponentProps<typeof Flex> {}

export const TableNavWrapper = ({ children, ...rest }: PropsWithChildren<Props>) => (
  <Flex direction="column" gap={15} {...rest}>
    <NavigationTabs className={styles.navigationTabs} />

    {children}
  </Flex>
)

import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SvgNoRecords } from 'components/svg/network/SvgNoRecords'

interface Props {
  isEmpty: boolean
  search: string
}

export const useTableNoRowsOverlay = ({ isEmpty, search }: Props) => {
  const { t } = useTranslation()
  return useCallback(
    () => (
      <Flex direction="column" align="center" gap={12}>
        <SvgNoRecords />

        {isEmpty ? (
          <WppTypography type="m-strong">{t('master_data.table.placeholder.empty')}</WppTypography>
        ) : (
          <WppTypography type="m-strong">{t('master_data.table.placeholder.empty_search', { search })}</WppTypography>
        )}
      </Flex>
    ),
    [isEmpty, t, search],
  )
}

import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { Region } from 'types/masterData/regions'

type Params = PaginationParams<{
  search?: string
}>

export type RegionsListResponse = PaginatedResponse<Region>

export const fetchRegionsList = ({ search, page, itemsPerPage }: Params) =>
  facadeApi.get<RegionsListResponse>('/regions', {
    params: {
      page,
      itemsPerPage,
      filter: {
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })

export enum ApiQueryKeys {
  MASTER_DATA_AGENCIES = 'master-data-agencies',
  MASTER_DATA_BRANDS = 'master-data-brands',
  MASTER_DATA_CLIENTS = 'master-data-clients',
  MASTER_DATA_INDUSTRIES = 'master-data-industries',
  MASTER_DATA_MARKETS = 'master-data-markets',
  MASTER_DATA_REGIONS = 'master-data-regions',

  MASTER_DATA_DOWNLOAD_URLS = 'master-data-download-urls',
  USER_DETAILS_DOWNLOAD_URLS = 'user-details-download-urls',
}

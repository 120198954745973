export const routesManager = {
  home: {
    root: {
      pattern: '/',
      getURL: () => '/',
    },
  },
  masterData: {
    agencies: {
      pattern: '/agencies',
      getURL: () => '/agencies',
    },
    clients: {
      pattern: '/clients',
      getURL: () => '/clients',
    },
    brands: {
      pattern: '/brands',
      getURL: () => '/brands',
    },
    industries: {
      pattern: '/industries',
      getURL: () => '/industries',
    },
    markets: {
      pattern: '/markets',
      getURL: () => '/markets',
    },
    regions: {
      pattern: '/regions',
      getURL: () => '/regions',
    },
  },
  '404': {
    root: {
      pattern: '/404',
      getURL: () => '/404',
    },
  },
}

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { Agency, AgencyForm, AgencyFormDTO } from 'types/masterData/agencies'
import { urlRegExp, serviceNameRegExp } from 'utils/regexp'
import { allowEmptyStringRule } from 'utils/zod'

export const displayNameFieldCharacterMaxCount = 256
export const displayNameFieldCharacterMinCount = 3
export const serviceNameFieldCharacterMinCount = 3
export const serviceNameFieldCharacterMaxCount = 256
export const navigatorUrlFieldCharacterMaxCount = 256
export const primaryEmailFieldCharacterMaxCount = 256
export const primaryWebsiteFieldCharacterMaxCount = 256

export const mapFormToAgencyDTO = (agency: AgencyForm): AgencyFormDTO => ({
  displayName: agency.displayName,
  serviceName: agency.serviceName,
  navigatorUrl: agency.navigatorUrl,
  contact: {
    primaryEmail: agency.primaryEmail,
    primaryWebsite: agency.primaryWebsite,
  },
  logoThumbnail: agency.logoThumbnail,
  logoOriginal: agency.logoOriginal,
})

export const mapAgencyToForm = (agency: Agency): AgencyForm => {
  return {
    displayName: agency.displayName,
    serviceName: agency.serviceName,
    navigatorUrl: agency.navigatorUrl,
    primaryEmail: agency.contact ? agency.contact.primaryEmail : null,
    primaryWebsite: agency.contact ? agency.contact.primaryWebsite : null,
    logoThumbnail: agency.logoThumbnail,
    logoOriginal: agency.logoOriginal,
  }
}

export const useAgencyValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        logoOriginal: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        logoThumbnail: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        displayName: zod
          .string()
          .trim()
          .min(
            displayNameFieldCharacterMinCount,
            t('master_data.validation.display_name.min', {
              entity: t('common.master_data.entities.agency'),
              characterCount: displayNameFieldCharacterMinCount,
            }),
          )
          .max(
            displayNameFieldCharacterMaxCount,
            t('master_data.validation.display_name.max', {
              entity: t('common.master_data.entities.agency'),
              characterCount: displayNameFieldCharacterMaxCount,
            }),
          ),
        serviceName: zod
          .string()
          .trim()
          .regex(serviceNameRegExp, t('master_data.validation.service_name.not_valid'))
          .min(
            serviceNameFieldCharacterMinCount,
            t('master_data.validation.service_name.min', {
              entity: t('common.master_data.entities.agency'),
              characterCount: serviceNameFieldCharacterMinCount,
            }),
          )
          .max(
            serviceNameFieldCharacterMaxCount,
            t('master_data.validation.service_name.max', {
              entity: t('common.master_data.entities.agency'),
              characterCount: serviceNameFieldCharacterMaxCount,
            }),
          ),
        navigatorUrl: allowEmptyStringRule(
          zod
            .string()
            .trim()
            .regex(urlRegExp, t('master_data.validation.navigator_url.not_valid'))
            .max(
              navigatorUrlFieldCharacterMaxCount,
              t('master_data.validation.navigator_url.max', {
                entity: t('common.master_data.entities.agency'),
                characterCount: navigatorUrlFieldCharacterMaxCount,
              }),
            )
            .nullable(),
        ),
        primaryEmail: allowEmptyStringRule(
          zod
            .string()
            .trim()
            .email(t('master_data.validation.primary_email.not_valid'))
            .max(
              primaryEmailFieldCharacterMaxCount,
              t('master_data.validation.primary_email.max', {
                entity: t('common.master_data.entities.agency'),
                characterCount: primaryEmailFieldCharacterMaxCount,
              }),
            )
            .nullable(),
        ),
        primaryWebsite: allowEmptyStringRule(
          zod
            .string()
            .trim()
            .regex(urlRegExp, t('master_data.validation.primary_website.not_valid'))
            .max(
              primaryWebsiteFieldCharacterMaxCount,
              t('master_data.validation.primary_website.max', {
                entity: t('common.master_data.entities.agency'),
                characterCount: primaryWebsiteFieldCharacterMaxCount,
              }),
            )
            .nullable(),
        ),
      }),
    [t],
  )
}

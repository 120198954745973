import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateAgencyApi } from 'api/masterData/mutations/useCreateAgencyApi'
import { useEditAgencyApi } from 'api/masterData/mutations/useEditAgencyApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import styles from 'pages/agencies/manageAgencyModal/ManageAgencyModal.module.scss'
import { useAgencyValidationScheme, mapFormToAgencyDTO, mapAgencyToForm } from 'pages/agencies/manageAgencyModal/utils'
import { handleReloadAgenciesListTable } from 'pages/agencies/utils'
import { hideViewAgencyModal } from 'pages/agencies/viewAgencyModal/ViewAgencyModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { AgencyForm, Agency } from 'types/masterData/agencies'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

const defaultValues: AgencyForm = {
  displayName: '',
  serviceName: '',
  navigatorUrl: null,
  primaryEmail: null,
  primaryWebsite: null,
  logoThumbnail: null,
  logoOriginal: null,
}

interface Props extends NiceModalWrappedProps {
  agency?: MayBeNull<Agency>
}

const ManageAgencyModal = ({ isOpen, agency, onCloseComplete, onClose, id }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const { mutateAsync: handleCreate } = useCreateAgencyApi()
  const { mutateAsync: handleEdit } = useEditAgencyApi()

  const form = useForm({
    defaultValues: agency ? mapAgencyToForm(agency) : defaultValues,
    validationSchema: useAgencyValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: AgencyForm) => {
    try {
      await handleCreate({
        agency: mapFormToAgencyDTO(values),
      })

      await handleReloadAgenciesListTable()

      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.agency') }),
        type: 'success',
      })

      onClose()
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: AgencyForm) => {
    try {
      await handleEdit({
        id: agency!.id,
        agency: mapFormToAgencyDTO(values),
      })

      await handleReloadAgenciesListTable()

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_AGENCIES] })

      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.agency') }),
        type: 'success',
      })

      onClose()
      hideViewAgencyModal()
    } catch {
      onError()
    }
  }

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{
          onSubmit: handleSubmit(agency ? onEditSubmit : onCreateSubmit),
        }}
        data-testid={id}
        open={isOpen}
        size="m"
        disableOutsideClick
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t(agency ? 'master_data.manage_modal.header.edit' : 'master_data.manage_modal.header.add', {
            entity: t('common.master_data.entities.agency'),
          })}
        </WppTypography>

        <Flex className={styles.modal} slot="body" direction="column" gap={28}>
          <Flex direction="column" gap={24} slot="body">
            <Flex align="center">
              <ManageMasterDataLogoUpload name={agency?.displayName || ''} />
            </Flex>
          </Flex>
          <FormTextInput
            name="displayName"
            labelConfig={{
              text: t('master_data.fields.display_name.label'),
            }}
            required
          />
          <FormTextInput
            name="serviceName"
            labelConfig={{
              text: t('master_data.fields.service_name.label'),
            }}
            required
          />
          <FormTextInput
            name="navigatorUrl"
            labelConfig={{
              text: t('master_data.fields.navigator_url.label'),
            }}
          />
          <FormTextInput
            name="primaryEmail"
            labelConfig={{
              text: t('master_data.fields.primary_email.label'),
            }}
          />
          <FormTextInput
            name="primaryWebsite"
            labelConfig={{
              text: t('master_data.fields.primary_website.label'),
            }}
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" loading={isSubmitting} data-testid="apply">
            {t('common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showManageAgencyModal } = createNiceModal(ManageAgencyModal, 'edit-agency-modal')

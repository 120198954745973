import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

export const nameFieldCharacterMaxCount = 256

export const useRegionValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        name: zod
          .string()
          .trim()
          .min(
            2,
            t('master_data.validation.name.min', {
              entity: t('common.master_data.entities.region'),
              characterCount: 2,
            }),
          )
          .max(
            nameFieldCharacterMaxCount,
            t('master_data.validation.name.max', {
              entity: t('common.master_data.entities.region'),
              characterCount: nameFieldCharacterMaxCount,
            }),
          ),
      }),
    [t],
  )
}

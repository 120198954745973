// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pweUl{position:relative;width:100%;max-width:var(--wpp-os-content-max-width, auto);min-height:calc(100vh - var(--wpp-os-header-height));margin:0 auto;padding-top:25px}.uvlDb{height:inherit}.w1zhC{flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/components/surface/layout/Layout.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,UAAA,CACA,+CAAA,CACA,oDAAA,CACA,aAAA,CACA,gBAAA,CAGF,OACE,cAAA,CAGF,OACE,WAAA","sourcesContent":[".root {\n  position: relative;\n  width: 100%;\n  max-width: var(--wpp-os-content-max-width, auto);\n  min-height: calc(100vh - var(--wpp-os-header-height));\n  margin: 0 auto;\n  padding-top: 25px;\n}\n\n.gridContainer {\n  height: inherit;\n}\n\n.gridItem {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `pweUl`,
	"gridContainer": `uvlDb`,
	"gridItem": `w1zhC`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jOKGA{padding:0 0 32px}`, "",{"version":3,"sources":["webpack://./src/pages/clients/manageClientModal/ManageClientModal.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA","sourcesContent":[".modal {\n  padding: 0 0 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `jOKGA`
};
export default ___CSS_LOADER_EXPORT___;

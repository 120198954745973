import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useGenerateMasterDataDownloadUrlsApi } from 'api/attachments/queries/useGenerateMasterDataDownloadUrlsApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { showDeleteClientModal } from 'pages/clients/deleteClientModal/DeleteClientModal'
import { Client } from 'types/masterData/clients'
import { excludeFalsy } from 'utils/common'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onEdit: () => void
  client: Client
}

const ViewClientModal = ({ isOpen, client, onCloseComplete, onClose, onEdit, id }: Props) => {
  const { t } = useTranslation()

  const { data: logoDownloadInfo } = useGenerateMasterDataDownloadUrlsApi({
    params: {
      keys: [client?.logoThumbnail].filter(excludeFalsy).map(({ key }) => key),
    },
  })

  const [logoThumbnailDownloadInfo] = logoDownloadInfo

  return (
    <SideModal
      open={isOpen}
      onWppSideModalCloseComplete={onCloseComplete}
      onWppSideModalClose={onClose}
      size="m"
      data-testid={id}
    >
      <WppTypography slot="header" type="2xl-heading">
        {client.name}
      </WppTypography>
      <Flex direction="column" gap={24} slot="body">
        <Flex align="center">
          <Avatar slot="left" size="l" name={client.name || ''} src={logoThumbnailDownloadInfo?.signed_url} />
        </Flex>
      </Flex>

      <Flex slot="actions" gap={12} justify="between">
        <WppButton variant="destructive" onClick={() => showDeleteClientModal({ client, onClose })}>
          {t('common.delete')}
        </WppButton>
        <Flex gap={12}>
          <WppButton variant="secondary" onClick={onEdit}>
            {t('common.edit')}
          </WppButton>
          <WppButton variant="primary" size="m" onClick={onClose}>
            {t('common.done')}
          </WppButton>
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showViewClientModal, hideModal: hideViewClientModal } = createNiceModal(
  ViewClientModal,
  'view-client-modal',
)

import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateBrandApi } from 'api/masterData/mutations/useCreateBrandApi'
import { useEditBrandApi } from 'api/masterData/mutations/useEditBrandApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import styles from 'pages/brands/manageBrandModal/ManageBrandModal.module.scss'
import { useBrandValidationScheme } from 'pages/brands/manageBrandModal/utils'
import { handleReloadBrandsListTable } from 'pages/brands/utils'
import { hideViewBrandModal } from 'pages/brands/viewBrandModal/ViewBrandModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { BrandForm, Brand, BrandFormDTO } from 'types/masterData/brands'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

const defaultValues: BrandForm = {
  name: '',
  logoThumbnail: null,
  logoOriginal: null,
}

interface Props extends NiceModalWrappedProps {
  brand?: MayBeNull<Brand>
}

const ManageBrandModal = ({ isOpen, brand, onCloseComplete, onClose, id }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const { mutateAsync: handleCreate } = useCreateBrandApi()
  const { mutateAsync: handleEdit } = useEditBrandApi()

  const form = useForm({
    defaultValues: brand || defaultValues,
    validationSchema: useBrandValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: BrandForm) => {
    try {
      await handleCreate({
        brand: values,
      })

      await handleReloadBrandsListTable()

      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.brand') }),
        type: 'success',
      })

      onClose()
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: BrandFormDTO) => {
    try {
      await handleEdit({
        id: brand!.id,
        brand: values,
      })

      await handleReloadBrandsListTable()

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_BRANDS] })

      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.brand') }),
        type: 'success',
      })

      onClose()
      hideViewBrandModal()
    } catch {
      onError()
    }
  }

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{
          onSubmit: handleSubmit(brand ? onEditSubmit : onCreateSubmit),
        }}
        data-testid={id}
        open={isOpen}
        size="m"
        disableOutsideClick
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t(brand ? 'master_data.manage_modal.header.edit' : 'master_data.manage_modal.header.add', {
            entity: t('common.master_data.entities.brand'),
          })}
        </WppTypography>

        <Flex className={styles.modal} slot="body" direction="column" gap={28}>
          <Flex direction="column" gap={24} slot="body">
            <Flex align="center">
              <ManageMasterDataLogoUpload name={brand?.name || ''} />
            </Flex>
          </Flex>
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
            }}
            required
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" loading={isSubmitting} data-testid="apply">
            {t('common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showManageBrandModal } = createNiceModal(ManageBrandModal, 'edit-brand-modal')

import { useCallback } from 'react'

import { useFetchMarketsApi } from 'api/masterData/queries/useFetchMarketsApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableDefaults, TableKey } from 'constants/table'
import { queryClient } from 'providers/osQueryClient/utils'
import { Market } from 'types/masterData/markets'

interface Params {
  search?: string
}

export const handleReloadMarketsListTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_MARKETS] })
  tableActions.reload(TableKey.MASTER_DATA_MARKETS)
}

export const useMarketsLoader = ({ search }: Params) => {
  const handleFetchMarketsList = useFetchMarketsApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<Market> = useCallback(
    async ({ startRow, endRow }) => {
      const size = endRow - startRow

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchMarketsList({
        itemsPerPage: size,
        search,
        page: endRow / size,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [search, handleFetchMarketsList],
  )

  return { loader }
}

import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateIndustryApi } from 'api/masterData/mutations/useCreateIndustryApi'
import { useEditIndustryApi } from 'api/masterData/mutations/useEditIndustryApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import styles from 'pages/industries/manageIndustryModal/ManageIndustryModal.module.scss'
import { useIndustryValidationScheme } from 'pages/industries/manageIndustryModal/utils'
import { handleReloadIndustriesListTable } from 'pages/industries/utils'
import { hideViewIndustryModal } from 'pages/industries/viewIndustryModal/ViewIndustryModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { IndustryForm, Industry, IndustryFormDTO } from 'types/masterData/industries'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

const defaultValues: IndustryForm = {
  name: '',
}

interface Props extends NiceModalWrappedProps {
  industry?: MayBeNull<Industry>
}

const ManageIndustryModal = ({ isOpen, industry, onCloseComplete, onClose, id }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const { mutateAsync: handleCreate } = useCreateIndustryApi()
  const { mutateAsync: handleEdit } = useEditIndustryApi()

  const form = useForm({
    defaultValues: industry || defaultValues,
    validationSchema: useIndustryValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: IndustryFormDTO) => {
    try {
      await handleCreate({
        industry: values,
      })

      await handleReloadIndustriesListTable()

      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.industry') }),
        type: 'success',
      })

      onClose()
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: IndustryForm) => {
    try {
      await handleEdit({
        id: industry!.id,
        industry: values,
      })

      await handleReloadIndustriesListTable()

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_INDUSTRIES] })

      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.industry') }),
        type: 'success',
      })

      onClose()
      hideViewIndustryModal()
    } catch {
      onError()
    }
  }

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{
          onSubmit: handleSubmit(industry ? onEditSubmit : onCreateSubmit),
        }}
        data-testid={id}
        open={isOpen}
        size="m"
        disableOutsideClick
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t(industry ? 'master_data.manage_modal.header.edit' : 'master_data.manage_modal.header.add', {
            entity: t('common.master_data.entities.industry'),
          })}
        </WppTypography>

        <Flex className={styles.modal} slot="body" direction="column" gap={28}>
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
            }}
            required
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" loading={isSubmitting} data-testid="apply">
            {t('common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showManageIndustryModal } = createNiceModal(ManageIndustryModal, 'edit-industry-modal')

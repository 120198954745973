import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { showDeleteIndustryModal } from 'pages/industries/deleteIndustryModal/DeleteIndustryModal'
import { Industry } from 'types/masterData/industries'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onEdit: () => void
  industry: Industry
}

const ViewIndustryModal = ({ isOpen, industry, onCloseComplete, onClose, onEdit, id }: Props) => {
  const { t } = useTranslation()

  return (
    <SideModal
      open={isOpen}
      onWppSideModalCloseComplete={onCloseComplete}
      onWppSideModalClose={onClose}
      size="m"
      data-testid={id}
    >
      <WppTypography slot="header" type="2xl-heading">
        {industry.name}
      </WppTypography>

      <Flex slot="actions" gap={12} justify="between">
        <WppButton variant="destructive" onClick={() => showDeleteIndustryModal({ industry, onClose })}>
          {t('common.delete')}
        </WppButton>
        <Flex gap={12}>
          <WppButton variant="secondary" onClick={onEdit}>
            {t('common.edit')}
          </WppButton>
          <WppButton variant="primary" size="m" onClick={onClose}>
            {t('common.done')}
          </WppButton>
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showViewIndustryModal, hideModal: hideViewIndustryModal } = createNiceModal(
  ViewIndustryModal,
  'view-industry-modal',
)

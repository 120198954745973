import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateMarketApi } from 'api/masterData/mutations/useCreateMarketApi'
import { useEditMarketApi } from 'api/masterData/mutations/useEditMarketApi'
import { Flex } from 'components/common/flex/Flex'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMarketLogoUpload } from 'components/logoUpload/ManageMarketLogoUpload'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import styles from 'pages/markets/manageMarketModal/ManageMarketModal.module.scss'
import { useMarketValidationScheme } from 'pages/markets/manageMarketModal/utils'
import { handleReloadMarketsListTable } from 'pages/markets/utils'
import { hideViewMarketModal } from 'pages/markets/viewMarketModal/ViewMarketModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { MarketForm, Market, MarketFormDTO } from 'types/masterData/markets'
import { Region } from 'types/masterData/regions'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

const defaultValues: MarketForm = {
  name: '',
  isoAlpha2: '',
  isoAlpha3: '',
  logoThumbnail: null,
  logoOriginal: null,
  regionId: null,
}

interface Props extends NiceModalWrappedProps {
  market?: MayBeNull<Market>
  regions: Region[]
}

const ManageMarketModal = ({ isOpen, market, regions, onCloseComplete, onClose, id }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const { mutateAsync: handleCreate } = useCreateMarketApi()
  const { mutateAsync: handleEdit } = useEditMarketApi()

  const form = useForm({
    defaultValues: market || defaultValues,
    validationSchema: useMarketValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: MarketForm) => {
    try {
      await handleCreate({
        market: {
          ...values,
          regionId: form.getValues('regionId'),
        },
      })

      await handleReloadMarketsListTable()

      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.market') }),
        type: 'success',
      })

      onClose()
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: MarketFormDTO) => {
    try {
      await handleEdit({
        id: market!.id,
        market: {
          ...values,
          regionId: form.getValues('regionId'),
        },
      })

      await handleReloadMarketsListTable()

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_MARKETS] })

      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.market') }),
        type: 'success',
      })

      onClose()
      hideViewMarketModal()
    } catch {
      onError()
    }
  }

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{
          onSubmit: handleSubmit(market ? onEditSubmit : onCreateSubmit),
        }}
        data-testid={id}
        open={isOpen}
        size="m"
        disableOutsideClick
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t(market ? 'master_data.manage_modal.header.edit' : 'master_data.manage_modal.header.add', {
            entity: t('common.master_data.entities.market'),
          })}
        </WppTypography>

        <Flex className={styles.modal} slot="body" direction="column" gap={28}>
          <Flex direction="column" gap={24} slot="body">
            <Flex align="center">
              <ManageMarketLogoUpload name={market?.name || ''} />
            </Flex>
          </Flex>
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
            }}
            required
          />
          <FormTextInput
            name="isoAlpha2"
            labelConfig={{
              text: t('master_data.fields.iso_alpha_2.label'),
            }}
            required
          />
          <FormTextInput
            name="isoAlpha3"
            labelConfig={{
              text: t('master_data.fields.iso_alpha_3.label'),
            }}
            required
          />
          <FormSelect
            name="regionId"
            labelConfig={{ text: t('master_data.fields.region.label') }}
            placeholder={t('master_data.fields.region.placeholder')}
            options={regions}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" loading={isSubmitting} data-testid="apply">
            {t('common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showManageMarketModal } = createNiceModal(ManageMarketModal, 'edit-market-modal')

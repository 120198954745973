import { useCallback } from 'react'

import { useFetchIndustriesApi } from 'api/masterData/queries/useFetchIndustriesApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableDefaults, TableKey } from 'constants/table'
import { queryClient } from 'providers/osQueryClient/utils'
import { Industry } from 'types/masterData/industries'

interface Params {
  search?: string
}

export const handleReloadIndustriesListTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_INDUSTRIES] })
  tableActions.reload(TableKey.MASTER_DATA_INDUSTRIES)
}

export const useIndustriesLoader = ({ search }: Params) => {
  const handleFetchIndustriesList = useFetchIndustriesApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<Industry> = useCallback(
    async ({ startRow, endRow }) => {
      const size = endRow - startRow

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchIndustriesList({
        itemsPerPage: size,
        search,
        page: endRow / size,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [search, handleFetchIndustriesList],
  )

  return { loader }
}

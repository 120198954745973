import {
  WppIconEdit,
  WppIconMore,
  WppIconRemoveCircle,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppTypography,
  WppActionButton,
  WppButton,
  WppIconPlus,
} from '@platform-ui-kit/components-library-react'
import { RowClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { AvatarWithNameCell } from 'components/cellRenderers/avatarWithName/AvatarWithNameCell'
import { AvatarWithInfoSkeleton } from 'components/cellRenderers/skeletons/avatarWithInfoSkeleton/AvatarWithInfoSkeleton'
import { ColDef, TableInfinite } from 'components/common/table'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { showDeleteClientModal } from 'pages/clients/deleteClientModal/DeleteClientModal'
import { showManageClientModal } from 'pages/clients/manageClientModal/ManageClientModal'
import { useClientsLoader } from 'pages/clients/utils'
import { showViewClientModal, hideViewClientModal } from 'pages/clients/viewClientModal/ViewClientModal'
import { TablePageWrapper } from 'pages/components/tablePageWrapper/TablePageWrapper'
import styles from 'pages/components/tablePageWrapper/TablePageWrapper.module.scss'
import { useTableNoRowsOverlay } from 'pages/hooks/useTableNoRowsOverlay'
import { Client as ClientType } from 'types/masterData/clients'
import { hasClosestInteractiveElement } from 'utils/dom'

interface State {
  isEmpty: boolean
  search: string
}

export const Clients = () => {
  const { t } = useTranslation()

  const [{ search, isEmpty }, setState] = useSetState<State>({
    isEmpty: false,
    search: '',
  })

  const { getAttachmentUrlByKey, loader } = useClientsLoader({ search })

  const setSearchDebounced = useDebounceFn(
    (search?: string) => setState({ search: search?.trim() || '' }),
    Delay.Search,
  )

  const columnDefs = useMemo<ColDef<ClientType>[]>(
    () => [
      {
        flex: 1,
        colId: 'name',
        loadingCellRenderer: AvatarWithInfoSkeleton,
        cellRenderer: ({ data }) => {
          const { name, logoThumbnail } = data!
          return <AvatarWithNameCell avatarThumbnail={getAttachmentUrlByKey(logoThumbnail?.key)} userName={name} />
        },
        headerName: t('tables.clients.columns.client_name'),
        tooltipValueGetter: ({ data }) => data!.name,
      },
      {
        width: 60,
        colId: 'actions',
        cellRenderer: ({ data }) => {
          return (
            <WppMenuContext
              className={styles.moreMenu}
              dropdownConfig={{
                appendTo: () => document.body,
                placement: 'bottom-end',
              }}
            >
              <WppActionButton slot="trigger-element">
                <WppIconMore direction="horizontal" />
              </WppActionButton>

              <WppListItem onWppChangeListItem={() => showManageClientModal({ client: data })}>
                <WppIconEdit slot="left" />
                <span slot="label">{t('common.edit')}</span>
              </WppListItem>

              <WppListItem onWppChangeListItem={() => showDeleteClientModal({ client: data! })}>
                <WppIconRemoveCircle slot="left" />
                <span slot="label">{t('common.delete')}</span>
              </WppListItem>
            </WppMenuContext>
          )
        },
      },
    ],
    [t, getAttachmentUrlByKey],
  )

  const noRowsOverlayComponent = useTableNoRowsOverlay({ isEmpty, search })

  const handleOnRowClicked = useStableCallback(async ({ event, data }: RowClickedEvent<ClientType>) => {
    const target = event?.target as HTMLElement

    if (data && !hasClosestInteractiveElement(target)) {
      showViewClientModal({
        onEdit: () => {
          showManageClientModal({ client: data, onOpen: hideViewClientModal })
        },
        client: data,
      })
    }
  })

  const ActionButtons = (
    <WppButton variant="secondary" onClick={() => showManageClientModal()}>
      <WppIconPlus slot="icon-start" />
      {t('common.create')}
    </WppButton>
  )

  return (
    <TablePageWrapper actionButtons={ActionButtons}>
      <WppTypography type="xl-heading" slot="header">
        {t('tables.clients.title')}
      </WppTypography>

      <div slot="actions">
        <WppInput
          size="s"
          type="search"
          onWppChange={({ detail }) => setSearchDebounced(detail.value)}
          className={styles.searchInput}
          placeholder={t('common.search')}
        />
      </div>

      <div className={styles.tableContainer}>
        <TableInfinite
          tableKey={TableKey.MASTER_DATA_CLIENTS}
          className={clsx('clients-table', styles.table)}
          columnDefs={columnDefs}
          noRowsOverlayComponent={noRowsOverlayComponent}
          onRowClicked={handleOnRowClicked}
          loader={loader}
          onLoadSuccess={({ isEmptySource }) => {
            setState({ isEmpty: isEmptySource && !search })
          }}
        />
      </div>
    </TablePageWrapper>
  )
}

import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { showDeleteRegionModal } from 'pages/regions/deleteRegionModal/DeleteRegionModal'
import { Region } from 'types/masterData/regions'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onEdit: () => void
  region: Region
}

const ViewRegionModal = ({ isOpen, region, onCloseComplete, onClose, onEdit, id }: Props) => {
  const { t } = useTranslation()

  return (
    <SideModal
      open={isOpen}
      onWppSideModalCloseComplete={onCloseComplete}
      onWppSideModalClose={onClose}
      size="m"
      data-testid={id}
    >
      <WppTypography slot="header" type="2xl-heading">
        {region.name}
      </WppTypography>

      <Flex slot="actions" gap={12} justify="between">
        <WppButton variant="destructive" onClick={() => showDeleteRegionModal({ region, onClose })}>
          {t('common.delete')}
        </WppButton>
        <Flex gap={12}>
          <WppButton variant="secondary" onClick={onEdit}>
            {t('common.edit')}
          </WppButton>
          <WppButton variant="primary" size="m" onClick={onClose}>
            {t('common.done')}
          </WppButton>
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showViewRegionModal, hideModal: hideViewRegionModal } = createNiceModal(
  ViewRegionModal,
  'view-region-modal',
)

import {
  WppIconEdit,
  WppIconMore,
  WppIconRemoveCircle,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppTypography,
  WppActionButton,
  WppButton,
  WppIconPlus,
} from '@platform-ui-kit/components-library-react'
import { RowClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { useRegionsApi } from 'api/masterData/queries/useRegionsApi'
import { AvatarWithNameCell } from 'components/cellRenderers/avatarWithName/AvatarWithNameCell'
import { AvatarWithInfoSkeleton } from 'components/cellRenderers/skeletons/avatarWithInfoSkeleton/AvatarWithInfoSkeleton'
import { ColDef, TableInfinite } from 'components/common/table'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { TablePageWrapper } from 'pages/components/tablePageWrapper/TablePageWrapper'
import styles from 'pages/components/tablePageWrapper/TablePageWrapper.module.scss'
import { useTableNoRowsOverlay } from 'pages/hooks/useTableNoRowsOverlay'
import { showDeleteMarketModal } from 'pages/markets/deleteMarketModal/DeleteMarketModal'
import { showManageMarketModal } from 'pages/markets/manageMarketModal/ManageMarketModal'
import { useMarketsLoader } from 'pages/markets/utils'
import { showViewMarketModal, hideViewMarketModal } from 'pages/markets/viewMarketModal/ViewMarketModal'
import { Market as MarketType } from 'types/masterData/markets'
import { normalize } from 'utils/common'
import { hasClosestInteractiveElement } from 'utils/dom'

interface State {
  isEmpty: boolean
  search: string
}

export const Markets = () => {
  const { t } = useTranslation()

  const { data: regions } = useRegionsApi()

  const regionsById = useMemo(() => normalize(regions, item => item.id), [regions])

  const [{ search, isEmpty }, setState] = useSetState<State>({
    isEmpty: false,
    search: '',
  })

  const setSearchDebounced = useDebounceFn(
    (search?: string) => setState({ search: search?.trim() || '' }),
    Delay.Search,
  )

  const columnDefs = useMemo<ColDef<MarketType>[]>(
    () => [
      {
        flex: 1,
        colId: 'name',
        loadingCellRenderer: AvatarWithInfoSkeleton,
        cellRenderer: ({ data }) => {
          const { name, logoThumbnail, logoOriginal } = data!
          return <AvatarWithNameCell avatarThumbnail={logoThumbnail?.url || logoOriginal?.url || ''} userName={name} />
        },
        headerName: t('tables.markets.columns.market_name'),
        tooltipValueGetter: ({ data }) => data!.name,
      },
      {
        flex: 1,
        colId: 'isoAlpha2',
        headerName: t('tables.markets.columns.iso_alpha_2'),
        valueGetter: ({ data }) => data!.isoAlpha2,
      },
      {
        flex: 1,
        colId: 'isoAlpha3',
        headerName: t('tables.markets.columns.iso_alpha_3'),
        valueGetter: ({ data }) => data!.isoAlpha3,
      },
      {
        flex: 1,
        colId: 'regionId',
        headerName: t('tables.markets.columns.region'),
        valueGetter: ({ data }) => (data!.regionId && regionsById[data!.regionId]?.name) || '',
      },
      {
        width: 60,
        colId: 'actions',
        cellRenderer: ({ data }) => {
          return (
            <WppMenuContext
              className={styles.moreMenu}
              dropdownConfig={{
                appendTo: () => document.body,
                placement: 'bottom-end',
              }}
            >
              <WppActionButton slot="trigger-element">
                <WppIconMore direction="horizontal" />
              </WppActionButton>

              <WppListItem onWppChangeListItem={() => showManageMarketModal({ market: data, regions })}>
                <WppIconEdit slot="left" />
                <span slot="label">{t('common.edit')}</span>
              </WppListItem>

              <WppListItem onWppChangeListItem={() => showDeleteMarketModal({ market: data! })}>
                <WppIconRemoveCircle slot="left" />
                <span slot="label">{t('common.delete')}</span>
              </WppListItem>
            </WppMenuContext>
          )
        },
      },
    ],
    [t, regions, regionsById],
  )

  const noRowsOverlayComponent = useTableNoRowsOverlay({ isEmpty, search })

  const handleOnRowClicked = useStableCallback(async ({ event, data }: RowClickedEvent<MarketType>) => {
    const target = event?.target as HTMLElement

    if (data && !hasClosestInteractiveElement(target)) {
      showViewMarketModal({
        onEdit: () => {
          showManageMarketModal({ market: data, regions, onOpen: hideViewMarketModal })
        },
        market: data,
        regionsById,
      })
    }
  })

  const { loader } = useMarketsLoader({ search })

  const ActionButtons = (
    <WppButton variant="secondary" onClick={() => showManageMarketModal({ regions })}>
      <WppIconPlus slot="icon-start" />
      {t('common.create')}
    </WppButton>
  )

  return (
    <TablePageWrapper actionButtons={ActionButtons}>
      <WppTypography type="xl-heading" slot="header">
        {t('tables.markets.title')}
      </WppTypography>
      <div slot="actions">
        <WppInput
          size="s"
          type="search"
          onWppChange={({ detail }) => setSearchDebounced(detail.value)}
          className={styles.searchInput}
          placeholder={t('common.search')}
        />
      </div>

      <div className={styles.tableContainer}>
        <TableInfinite
          tableKey={TableKey.MASTER_DATA_MARKETS}
          className={clsx('markets-table', styles.table)}
          columnDefs={columnDefs}
          noRowsOverlayComponent={noRowsOverlayComponent}
          onRowClicked={handleOnRowClicked}
          loader={loader}
          onLoadSuccess={({ isEmptySource }) => {
            setState({ isEmpty: isEmptySource && !search })
          }}
        />
      </div>
    </TablePageWrapper>
  )
}

import { WppTab, WppTabs } from '@platform-ui-kit/components-library-react'
import { ComponentProps, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import styles from 'components/navigationTabs/NavigationTabs.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props extends Partial<ComponentProps<typeof WppTabs>> {}

export const NavigationTabs = ({ ...rest }: Props) => {
  const { pathname } = useLocation()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const masterDataPaths = useMemo(
    () =>
      Object.entries(routesManager.masterData).map(([pathName, pathObj]) => {
        return [pathName, pathObj.pattern]
      }),
    [],
  )

  return (
    <WppTabs
      {...rest}
      value={pathname}
      onWppChange={event => navigate(event.detail.value)}
      data-testid="master-data-navigation-tabs"
    >
      {masterDataPaths.map(([pathName, path]) => (
        <WppTab className={styles.tab} value={path} key={path}>
          {t(`tables.${pathName}.title`)}
        </WppTab>
      ))}
    </WppTabs>
  )
}
